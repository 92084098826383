<template>
  <v-card height="616" class="mx-auto px-3 px-md-6 pb-6 fill-width">
    <!--    <div-->
    <!--      class="d-sm-flex align-sm-center justify-sm-space-between text-center my-4"-->
    <!--    >-->
    <!--      <div class="font-regular-12 gray9&#45;&#45;text">-->
    <!--        {{ $t("panel.settingsPage.accountInfoComponent.photo") }}-->
    <!--      </div>-->

    <!--      <attachment-->
    <!--        :file="userInfo.profilePicture"-->
    <!--        @pushFileUp="(v) => (userInfo.profilePicture = v)"-->
    <!--        @remove="userInfo.profilePicture = ''"-->
    <!--      />-->
    <!--    </div>-->
    <!--    <v-divider></v-divider>-->

    <div
      class="d-sm-flex align-sm-center justify-sm-space-between text-center my-6"
    >
      <div class="font-regular-12 gray9--text mb-md-0">
        {{ $t("panel.settingsPage.accountInfoComponent.email") }}
      </div>

      <span class="gray12--text">{{ userInfo.email }}</span>
    </div>
    <v-divider></v-divider>

    <div
      class="d-sm-flex align-sm-center justify-sm-space-between text-center my-4"
    >
      <div class="font-regular-12 gray9--text mb-md-0">
        {{ $t("panel.settingsPage.accountInfoComponent.name") }}
      </div>

      <v-col
        cols="12"
        sm="11"
        class="d-flex align-center justify-center flex-column flex-sm-row justify-sm-end pa-0"
      >
        <v-col
          cols="10"
          md="11"
          class="gray12--text pa-0 text-center text-sm-right"
        >
          {{ userInfo.firstName }} {{ userInfo.lastName }}
        </v-col>

        <v-btn text color="transparent" class="pa-0" @click="toggleNameModal">
          <span
            class="font-semiBold-14 primary--text text-decoration-underline"
          >
            <template v-if="userInfo.firstName && userInfo.lastName">
              {{ $t("panel.settingsPage.accountInfoComponent.edit") }}
            </template>

            <template v-else>
              {{ $t("panel.settingsPage.accountInfoComponent.set") }}
            </template>
          </span>
        </v-btn>
      </v-col>
    </div>
    <v-divider></v-divider>

    <div
      class="d-sm-flex align-sm-center justify-sm-space-between text-center my-6"
    >
      <div class="font-regular-12 gray9--text mb-md-0">
        {{ $t("panel.settingsPage.accountInfoComponent.localCurrency") }}
      </div>

      <span class="gray12--text">USD - United States Dollar</span>
    </div>

    <v-dialog v-model="nameModal" width="468">
      <v-btn
        icon
        color="transparent"
        @click="toggleNameModal"
        class="ml-auto pa-4 d-block"
        height="auto"
        width="auto"
      >
        <v-icon size="24">$close</v-icon>
      </v-btn>

      <div class="px-4 pb-6">
        <div class="font-medium-16 gray13--text text-center mb-6">
          {{ $t("panel.settingsPage.accountInfoComponent.yourFullName") }}
        </div>

        <v-form ref="form" v-model="valid" @submit.prevent="submit">
          <v-text-field
            v-model.trim="userInfoModel.firstName"
            :rules="firstNameRules"
            required
            :label="$t('panel.settingsPage.accountInfoComponent.firstName')"
            filled
            hide-details="auto"
            class="theme-custom-input mb-6"
          >
          </v-text-field>

          <v-text-field
            v-model.trim="userInfoModel.lastName"
            :rules="lastNameRules"
            required
            :label="$t('panel.settingsPage.accountInfoComponent.lastName')"
            filled
            hide-details="auto"
            class="theme-custom-input mb-8"
          >
          </v-text-field>

          <div class="d-md-flex align-center justify-space-between">
            <v-btn
              height="43"
              :block="$vuetify.breakpoint.mdAndDown"
              depressed
              color="primary"
              class="mb-3 mb-md-0 px-16"
              :loading="submitLoading"
              type="submit"
            >
              <span class="font-semiBold-14 px-6">
                {{ $t("panel.settingsPage.accountInfoComponent.save") }}
              </span>
            </v-btn>

            <v-btn
              height="43"
              :block="$vuetify.breakpoint.mdAndDown"
              depressed
              color="gray2"
              class="px-16"
              @click="toggleNameModal"
            >
              <span class="font-semiBold-14 px-4">
                {{ $t("panel.settingsPage.accountInfoComponent.cancel") }}
              </span>
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import { UserInfoService } from "@/services";
import { mapGetters, mapActions } from "vuex";
// import Attachment from "./Attachment";

export default {
  name: "account-info",
  // components: {
  //   Attachment,
  // },
  data() {
    return {
      submitLoading: false,
      nameModal: false,
      userInfoModel: {
        profilePicture: "",
        firstName: "",
        lastName: "",
      },
      valid: false,
      firstNameRules: [
        (v) =>
          !!v?.trim() ||
          this.$t("panel.settingsPage.accountInfoComponent.firstNameNotEmpty"),
        (v) =>
          v?.length <= 200 ||
          this.$t("panel.settingsPage.accountInfoComponent.firstNameLength"),
        (v) =>
          !/[^\w\s]/.test(v) ||
          this.$t(
            "panel.settingsPage.accountInfoComponent.firstNameSpecialCharacter"
          ),
      ],
      lastNameRules: [
        (v) =>
          !!v?.trim() ||
          this.$t("panel.settingsPage.accountInfoComponent.lastNameNotEmpty"),
        (v) =>
          v?.length <= 200 ||
          this.$t("panel.settingsPage.accountInfoComponent.lastNameLength"),
        (v) =>
          !/[^\w\s]/.test(v) ||
          this.$t(
            "panel.settingsPage.accountInfoComponent.lastNameSpecialCharacter"
          ),
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo/GET_USER_INFO",
    }),
  },
  mounted() {
    this.userInfoModel = Object.assign({}, this.userInfo);
  },
  methods: {
    ...mapActions({
      setUserInfo: "userInfo/SET_USER_INFO",
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.submitLoading = true;
      UserInfoService.updateUserInfo(this.userInfoModel)
        .then((res) => {
          this.setUserInfo(res.data.data);
          this.toggleNameModal();
          this.$toast.success(res.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    toggleNameModal() {
      this.nameModal = !this.nameModal;
      this.userInfoModel.firstName = this.userInfo.firstName;
      this.userInfoModel.lastName = this.userInfo.lastName;
      // this.$refs.form.resetValidation();
    },
  },
  watch: {
    nameModal(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "AccountInfo";
</style>
